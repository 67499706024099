import React from "react";


class SpecialistBlock extends React.Component {

    getExperience = (experience) => {
        if (experience == 11 || experience == 12 || experience == 13 || experience == 14)
            return experience + ' лет';

        let last = experience % 10;

        if (last === 1)
            return experience + ' года';

        if (last < 5 && last != 0)
            return experience + ' лет';

        return experience + ' лет';
    }

    getPrice = (price) => {
        return parseInt(price / 1000) + ' ' + price % 1000;
    }

    render () {


        return <div className="specialist-block" style={{maxWidth: '400px', height: '200px'}}>
            <div className="specialist-block-view">
                <img className="specialist-block-photo" src={"avatars/" + this.props.photo ?? 'default.png'} alt="Фото" style={{height: '200px', width: '200px', minWidth: '0px', minHeight: '0px'}}/>
                <div className="specialist-block-view-text">{this.props.name}</div>
            </div>
            <div style={{width: '100%'}}>
                <div className="specialist-block-experience">
                    <div className="specialist-block-experience-icon"><img src="images/icons/suitcase.svg" alt=""/></div>
                    <div className="specialist-block-experience-content">Более {this.getExperience(this.props.experience)}</div>
                    <div className="specialist-block-experience-desc">Работает с тревогой</div>
                </div>
                <div className="specialist-block-price" style={{paddingTop: '8px'}}>
                    <div className="specialist-block-experience-icon"><img src="images/icons/money.svg" alt=""/></div>
                    <div className="specialist-block-experience-content" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>3 950 ₽<img onClick={() => this.props.showPostpay()} src="images/icons/info-gray.svg" width={15} height={15}/></div>
                    <div className="specialist-block-price-text">сессия 55 мин</div>
                    <div onClick={() => this.props?.showUwioHealth()} className="specialist-block-price-text diagnostics-additional"><b>+ Uwio health бесплатно</b></div>
                </div>
            </div>
        </div>;
    }
}

export default SpecialistBlock;