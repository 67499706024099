import React from "react";
import connection from "../../scripts/connection";
import Loader from "../Loader";
import { decodeToken } from "react-jwt";

class FreeBookingModal extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            selectedTime: null
        }
    }

    componentDidMount() {
        this.loadTime()
    }

    componentDidUpdate(oldProps) {
        if (this.props.consultationType !== oldProps.consultationType)
            this.loadTime();
    }

    loadTime = () => {
        let consultationType = "&consultationType=" + (this.props.consultationType ?? 'Single');

        connection.Get('/booking/SpecialistTimePreview?specialistId=' + this.props.specialistId + '&timezoneOffset=' + this.props.timezoneOffset,
        (success) => {


            let availableTime = {};
            success.value.forEach(i => {
                let dt = new Date(i);

                let dateOnly = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
                if (availableTime[dateOnly])
                    availableTime[dateOnly].push(dt)
                else
                    availableTime[dateOnly] = [dt]
            })

            this.setState({
                availableTime: availableTime
            })
        },
        (error) => {
            console.log(error)
        },
        (fatalError) => {
            console.log(fatalError)
        })
    }

    getDateDiplay = (date) => {
        date = new Date(date)

        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

        let day = date.getDate() + ' ' + months[date.getMonth()];
        let dayOfWeek = days[date.getDay()];

        return day + ', ' + dayOfWeek;
    }

    getSelectedDateDisplay = (date) => {
        let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

        // Время
        // День с месяцем
        // день недели
        let time = date.getUTCHours() + ':' + (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes());
        let day = date.getUTCDate() + ' ' + months[date.getUTCMonth()];
        let dayOfWeek = days[date.getUTCDay()];


        return day + ', ' + time;
    }

    getTimeBlock = (availableTime) => {
        let result = [];

        Object.keys(availableTime).forEach((date, dateIndex) => {
            
            let dateDisplay = this.getDateDiplay(date);

            let timeDisplay = availableTime[date].map((time, index) => {
                return  <span onClick={() => this.setState({selectedTime: time})}
                            key={index}
                            className="booking-time-item"
                            style={this.state.selectedTime === time ? {backgroundColor: '#3A767B', color: 'white'} : undefined}
                        >
                            {time.getUTCHours() < 10 ? '0' + time.getUTCHours() : time.getUTCHours()}:00
                        </span>;
            })

            result.push(<div>
            <div key={dateIndex} className="booking-date">{dateDisplay}</div>
                <div className="booking-time-row">
                    {timeDisplay}
                </div>
            </div>)
        })

        return result;
    }


    render() {

        return <div className="modal-block" onClick={() => this.props.onHide()}>
            <div className="booking-modal body-text-small" onClick={(e) => e.stopPropagation()}>
               <div style={{position: 'absolute', right: '20px', cursor: 'pointer'}}  onClick={(e) => this.props.onHide()}>X</div>
               {!this.props.hideHeader ?
               <div className="booking-header">
                    <img className="booking-photo" src={"avatars/" + this.props.specialistPhotoUrl} />
                    <div className="booking-description">
                        <div className="booking-specialistname">{this.props.specialistName}</div>
                        <div className="booking-session-details">
                            <div>{this.props.consultationType === "Double" ? 'Парная' : 'Индивидуальная'} сессия</div>
                            <div>{this.props.consultationType === "Double" ? '100' : '55'} мин{this.props.price ? ', ' + this.props.price + ' ₽' : null}</div>
                        </div>
                    </div>
               </div>
               :
               undefined
                }
               {this.props.consultationTypeSelector ?
                        <div style={{marginBottom: '1rem', marginRight: '1.5rem'}} className="reg-block-toggle">
                            <div onClick={() => this.props.onConsultationTypeSelected('Single')} className={"reg-toggle toggle-left" + (this.props.consultationType === "Single" ? ' toggle-active' : '')}>Индивидуальная</div>
                            <div onClick={() => this.props.onConsultationTypeSelected('Double')} className={"reg-toggle toggle-right" + (this.props.consultationType === "Double" ? ' toggle-active' : '')}>Парная</div>
                        </div>
                        :
                        undefined
                }
               {this.state.availableTime ? <div className="time-blocks-container">{this.getTimeBlock(this.state.availableTime)}</div>
               :
                <Loader />
               }
               {this.state.selectedTime ?
                    <button onClick={() => this.props.register(this.state.selectedTime)} className="booking-action">Выбрать {this.getSelectedDateDisplay(this.state.selectedTime)}</button>
                :
                    <button className="booking-action" style={{background: 'white', color: '#3A767B', border: '1px solid #3A767B'}}>Время не выбрано</button>
               }
            </div>
        </div>;
    }
}

export default FreeBookingModal;