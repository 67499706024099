import React from "react";


class TelegramSpecialistBlock extends React.Component {

    getExperience = (experience) => {
        if (experience == 11 || experience == 12 || experience == 13 || experience == 14)
            return experience + ' лет';

        let last = experience % 10;

        if (last === 1)
            return experience + ' год';

        if (last < 5 && last != 0)
            return experience + ' года';

        return experience + ' лет';
    }

    getPrice = (price) => {
        return parseInt(price / 1000) + ' ' + price % 1000;
    }

    consultationTime = (time) => {
        let dt = new Date(time);
        let day = dt.getUTCDate() < 10 ? '0' + dt.getUTCDate() : dt.getUTCDate();
        let month = (dt.getUTCMonth() + 1) < 10 ? '0' + (dt.getUTCMonth() + 1) : (dt.getUTCMonth() + 1);
        let year = dt.getUTCFullYear() % 100;

        let minutes = dt.getUTCMinutes() < 10 ? '0' + dt.getUTCMinutes() : dt.getUTCMinutes();
        let hours = dt.getUTCHours() < 10 ? '0' + dt.getUTCHours() : dt.getUTCHours();

        let monthsList = [ 'января', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

        return <>{day + ' ' + monthsList[month]}<br/>{hours + ':' + minutes}</>;
    }

    render () {


        return <div className="specialist-block">
            <div className="specialist-block-view">
                <img className="specialist-block-photo" src={"avatars/" + this.props.photo ?? 'default.png'} alt="Фото"/>
                <div className="specialist-block-view-text">{this.props.name}</div>
            </div>
            <div>
                <div className="specialist-block-experience">
                    <div className="specialist-block-experience-icon"><img src="images/icons/suitcase.svg" alt=""/></div>
                    <div className="specialist-block-experience-content">{this.getExperience(this.props.experience)}</div>
                    <div className="specialist-block-experience-desc">Опыт</div>
                </div>
                <div className="specialist-block-price">
                    <div className="specialist-block-experience-icon"><img src="images/icons/calendar.svg" alt=""/></div>
                    <div className="specialist-block-experience-content">{this.consultationTime(this.props.closestTime)}</div>
                    <div className="specialist-block-price-text">Ближайшая запись</div>
                </div>
            </div>
        </div>;
    }
}

export default TelegramSpecialistBlock;