import React from "react";



class TelegramFaqBlock extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openedQuestion: 'Q0',
            openedSubQuestion: null,
            faq: [
                {
                    show: true
                },
                {
                    show: true
                },
                {
                    show: false
                },
                {
                    show: false
                }
            ]
        }
    }

    openQuestion = (questionId) => {
        if (this.state.openedQuestion == questionId)
            questionId = null;
            
        this.setState({openedQuestion: questionId});
    }

    openSubQuestion = (subQuestionId) => {
        if (subQuestionId == this.state.openedSubQuestion)
            subQuestionId = null;

        this.setState({openedSubQuestion: subQuestionId});
    }


    getOpenCloseElement = (theme, item) => {
        if (!this.state.faq[theme][item])
            return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 8L12 16L20 8" stroke="#474747" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>;
        return <svg style={{rotate: '180deg'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M4 8L12 16L20 8" stroke="#474747" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>;
    }

    showFaqTheme = (theme) => {
        if (this.state.faq[theme].show)
        {
            let faq = this.state.faq;
            faq[theme] = { show: false };
            this.setState({faq: faq});
        }
        else
        {
            let faq = this.state.faq;
            faq[theme] = { show: true };
            this.setState({faq: faq});
        }
    }

    showFaqThemeContent = (theme, content) => {
        if (!this.state.faq[theme][content])
        {
            
            let faq = this.state.faq;
            faq[theme][content] = true;
            this.setState({faq: faq});
        }
        else
        {
            let faq = this.state.faq;
            faq[theme][content] = false;
            this.setState({faq: faq});
        }
    }



    render () {
        
        return  <div className="about-block" style={{paddingLeft: '19px', paddingRight: '19px'}}>
        <h2 className="reg-block-header">Частые вопросы</h2>
        <div className="faq-block" style={{background: 'inherit'}}>
            <div  className="faq-header">
                <div className="faq-title">О процессе терапии</div>
            </div>
            <div style={this.state.faq[0]?.show ? {paddingLeft: '0'} : {display: 'none', paddingLeft: '0'}} className="faq-content">
                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 7)} className="faq-header">
                        <div className="faq-title">Где будет проходить терапия?</div>
                        <div>
                            {this.getOpenCloseElement(0, 7)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][7] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        <b>Терапия будет проходить в телеграм-приложении</b>
                        <br/><br/>
                        В приложении вы сможете:
                        <br/><br/>
                        ➜ Посещать видео-консультации с психотерапевтом<br/>
                        ➜ Управлять расписанием консультаций<br/>
                        ➜ Выполнять практики и получать необходимые знания<br/>
                        ➜ Контролировать свой прогресс
                        <br/><br/>
                        Приложение доступно с мобильной и пк версий Telegram, защищено протоколом конфиденциальности и не требует регистрации по номеру телефона
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 0)} className="faq-header">
                        <div className="faq-title">Как я пойму, что терапия результативна?</div>
                        <div>
                            {this.getOpenCloseElement(0, 0)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][0] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">

                        <b>Терапия в Uwio абсолютно прозрачна</b> — свой прогресс вы сможете видеть на панели аналитики в нашем приложении.<br/><br/>
                        У вас будет возможность наблюдать за изменением уровня тревожности, качества сна, самочувствия, настроения, самоконтроля и других важных психоэмоциональных и физических параметров
                    </div>
                </div>

                                           

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 1)} className="faq-header">
                        <div className="faq-title">Как проходит 1-ая сессия с психологом?</div>
                        <div>
                            {this.getOpenCloseElement(0, 1)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][1] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Первая сессия отводится на диагностику, уточнение запроса, знакомство и формирование целей психотерапии.
                        <br/><br/>
                        Для того, чтобы понять реальные причины ваших проблем и сформировать план терапии, психолог задаст  несколько вопросов о вашем  состоянии, вашей жизни и сопутствующих факторах, которые формируют нежелательный эмоциональный статус и деструктивные поведенческие реакции.<br/>                        По итогам первой сессии у вас появится детальное видение вашей проблемы и план работы с психологом.
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 2)} className="faq-header">
                        <div className="faq-title">Как часто нужно встречаться со специалистом?</div>
                        <div>
                            {this.getOpenCloseElement(0, 2)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][2] ? {} : {display: 'none'}} className="faq-content diagnostics-faq-content">
                        В первое время оптимальная частота занятий - раз в неделю.
                        <br/><br/>
                        Такая периодичность встреч позволит вам погрузиться в работу, делать домашние задания, которые дал вам психолог  и наблюдать за изменениями в своей жизни. 
                        В дальнейшем вы можете встречаться с психологом реже.
                        В кризисных состояниях частота встреч с психологом может доходить до 2-3 раз в неделю.
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 4)} className="faq-header">
                        <div className="faq-title">Сколько будет длиться терапия?</div>
                        <div>
                            {this.getOpenCloseElement(0, 4)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][4] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Философия Uwio - краткосрочная и сфокусированная на результате терапия, поэтому мы изначально стремимся помочь вам достичь изменений в оптимальные сроки. 
                        <br/><br/>
                        В среднем, на проработку конкретной проблемы требуется, примерно, 3-6 сессий.
                        <br/><br/>
                        Если вам нужны более глубокие изменения и регулярная поддержка специалиста, после окончания основного курса вы можете продолжить ваши занятия с психологом в формате дополняющих встреч.
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 5)} className="faq-header">
                        <div className="faq-title">Смогу ли я сменить специалиста?</div>
                        <div>
                            {this.getOpenCloseElement(0, 5)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][5] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Если вас что-то не устроит в работе с вашим психотерапевтом, вы сможете бесплатно выбрать другого специалиста
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(0, 6)} className="faq-header">
                        <div className="faq-title">Сколько времени занимают самостоятельные практики между сессиями?</div>
                        <div>
                            {this.getOpenCloseElement(0, 6)}
                        </div>
                    </div>
                    <div style={this.state.faq[0][6] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                    Наша программа очень гибкая. Вам потребуется всего 10-15 минут, чтобы выполнить ежедневные упражнения, практики или изучить короткий урок. Вы можете работать по программе в своём собственном темпе между сессиями с психотерапевтом.
                    <br/><br/>
                    <b>Главная задача практик между сессиями — сократить длительность терапии и сделать результаты устойчивыми</b>
                    </div>
                </div>

            </div>
        </div>

        <div className="faq-block" style={{background: 'inherit'}}>
            <div  className="faq-header">
                <div className="faq-title">Запись к психологу и оплата</div>
            </div>
            <div style={this.state.faq[1]?.show ? {} : {display: 'none'}} className="faq-content">
                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(1, 0)} className="faq-header">
                        <div className="faq-title">Как оплатить консультацию?</div>
                        <div>
                            {this.getOpenCloseElement(1, 0)}
                        </div>
                    </div>
                    <div style={this.state.faq[1][0] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Оплатить консультацию необходимо на нашей платформе, после выбора даты и времени.
                        <br/><br/>
                        Оплата сессий в сервисе проходит через безопасную сделку. За 8 часов до консультации с психологом стоимость сессии спишется с вашей карты и заморозится. На счёт специалиста деньги поступят только после сессии. Таким образом мы можем гарантировать, что вы получите консультацию, а психолог оплату своего труда. Если специалист не провел сессию или что-то пошло не так, обратитесь в службу поддержки - мы вернем деньги.
                        <br/><br/>
                        Ссылка на следующие записи приходят по SMS.
                    </div>
                </div>

               

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(1, 1)} className="faq-header">
                        <div className="faq-title">Что будет после оплаты?</div>
                        <div>
                            {this.getOpenCloseElement(1, 1)}
                        </div>
                    </div>
                    <div style={this.state.faq[1][1] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        После оплаты автоматически переведем вас в личный кабинет и подключим к нашему телеграм-приложению - в нем вы сможете связаться с вашим психологом, управлять расписанием сессий, а так же работать с инструментами,  практиками и отслеживать результаты.
                    </div>
                </div>

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(1, 2)} className="faq-header">
                        <div className="faq-title">Как перенести или отменить сессию с психологом?</div>
                        <div>
                            {this.getOpenCloseElement(1, 2)}
                        </div>
                    </div>
                    <div style={this.state.faq[1][2] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Отменить или перенести сессию вы можете в вашем личном кабинете на панели управления сессиями. 
                        <br/><br/>
                        Бесплатная отмена и перенос сессии возможны более чем за 8 часов до ее начала.
                    </div>
                </div>

                <div className="faq-content-block diagnostics-faq">
                    <div onClick={() => this.showFaqThemeContent(1, 3)} className="faq-header">
                        <div className="faq-title">Могу ли связаться со специалистом перед сессией?</div>
                        <div>
                            {this.getOpenCloseElement(1, 3)}
                        </div>
                    </div>
                    <div style={this.state.faq[1][3] ? {} : {display: 'none'}} className="faq-content-entire diagnostics-faq-content">
                        Да, в вашем личном кабинете будет чат с вашим психологом.
                        <br/>
                        Также чатом можно пользоваться и  между сессиями.
                    </div>
                </div>
                
                
            </div>
        </div>


    </div>;
    }
}

export default TelegramFaqBlock;