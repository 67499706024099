import React from "react";


class DiscountBannerMobile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }


    render () {

        return <div className='discount-banner'>
            <div className="discount-header"><b>Скидка 40%</b> на первую сессию</div>
            <div className="discount-content">
                <div className="discount-text">Промокод «<b>ВЕСНА40</b>» до 9 марта</div>
                <div>
                    {this.state.bannerButton ?
                        <button style={{color: '#FFFFFF', backgroundColor: '#669DA2'}} className="discount-button">Скопировано</button>
                    :
                        <button onClick={() => navigator.clipboard.writeText('ВЕСНА20').then(() => this.setState({bannerButton: true}))} className="discount-button">{this.state.bannerButton ? 'Скопировано' : 'Скопировать'}</button>
                    }
                </div>
            </div>
        </div>
    }
}

export default DiscountBannerMobile;