import React from "react";


class TgRedirect extends React.Component {


    render() {
        return <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <a href="https://t.me/uwiohealth_bot" style={{padding: '10px 20px', background: '#E26833', marginLeft: 'auto', marginRight: 'auto', color: 'white', borderRadius: '15px'}}>Перейти в телеграм</a>
        </div>;
    }
}

export default TgRedirect;